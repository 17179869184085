import React from "react"
import Layout from "../common/layout/layout"
import SEO from "../common/seo/seo"
import CaseStudyLaLaAnthonyTemplate from "../modules/caseStudy/CaseStudyLaLaAnthony.template"

const CaseStudyLalaAnthony = ({ data }) => {
  return (
    <Layout>
      <SEO title="Case Study LaLa Anthony" />
      <CaseStudyLaLaAnthonyTemplate image={data.caseStudyLaLaAnthony.edges} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    caseStudyLaLaAnthony: allFile(
      filter: { relativeDirectory: { eq: "caseStudyLaLaAnthony" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`

export default CaseStudyLalaAnthony
