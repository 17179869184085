import React from "react"
import CaseStudyHeaderSection from "./components/CaseStudyHeaderSection"
import CaseStudyDescriptionSection from "./components/CaseStudyDescriptionSection"
import CaseStudyWorkSection from "./components/CaseStudyWorkSection"
import NewsletterSection from "../newsletter/NewsletterSection"

const CaseStudyLaLaAnthonyTemplate = ({ image }) => {
  return (
    <React.Fragment>
      <CaseStudyHeaderSection
        title="La La Anthony"
        description="A top TV personality launches her new Facebook Watch show with Hook Points"
        image={image}
      />
      <CaseStudyDescriptionSection
        list={
          <ul>
            <li>
              We provided strategy to help television personality, author and
              actress Lala Anthony <span>successfully launch</span> her new
              Facebook Watch show
            </li>
            <li>
              Crafted dynamic and compelling Hook Points to{" "}
              <span>elevate the show’s first episode</span> and attract viewers
              online
            </li>
            <li>
              The result was <span>over 2.1 million</span> views to her first
              Facebook Watch show episode, helping Lala establish herself as a
              multiplatform talent with reach across both traditional and online
              television
            </li>
          </ul>
        }
      />
      <CaseStudyWorkSection
        next={"/case-study-katie-couric"}
        text="Would you like to get more details about how Lala succeeded with Hook Points?"
        subText=" We’d love to tell you more"
      />
      <NewsletterSection />
    </React.Fragment>
  )
}

export default CaseStudyLaLaAnthonyTemplate
